<template>
  <div id="outboundsBatchApply">
    <header-nav :content="`${$t('pages_router_036')} / ${ $t('pages_router_025')}`" />

    <div class="common-card-body">
      <div class="batch-header" @click="$router.replace('/funds/outbounds')">
        <left-outlined class="ic-back" />
        {{ $t('pages_router_012') }}
      </div>
      <div class="apply-area">
        <a-form
          ref="applyRef"
          class="ant-round-form batch-apply-form"
          layout="vertical"
          :model="applyForm"
          :rules="applyRules"
          @finish="applySubmit"
        >

          <a-form-item name="countryCode" :label="$t('pages_pay_281')">
            <a-select
              v-model:value="applyForm.countryCode"
              allow-clear
              show-search
              option-label-prop="label"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_pay_281')"
              @change="loadPayeeCurrencys"
            >
              <a-select-option
                v-for="item in countries"
                :key="item.id"
                :value="item.country"
                :label="`${item.country} - ${item.countryChineseName}`"
              >
                <!-- <img :src="getCountryLogo(item.areaCode3)" class="select-img-country"> -->
                <span>{{ item.country }} - {{ item.countryChineseName }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-row>
            <a-col :span="11">
              <a-form-item name="sellCurrency">
                <template #label>
                  <div class="flex-align" style="width: 100%">
                    <span>{{ $t('pages_pay_071') }}</span>
                    <span v-if="availableAmount !== null" class="amount-text">{{ $t('pages_pay_124') }}: {{ availableAmount }}</span>
                  </div>
                </template>
                <a-select
                  v-model:value="applyForm.sellCurrency"
                  size="large"
                  allow-clear
                  show-search
                  class="cur-select"
                  :placeholder="$t('common_text_005')"
                  @change="loadOutPayType"
                >
                  <template #suffixIcon>
                    <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                  </template>
                  <a-select-option
                    v-for="item in debitCurrencys"
                    :key="item"
                    :value="item"
                  >
                    <img :src="getCountryLogo(item)" class="select-img-country">
                    <span>{{ item }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="2" />
            <a-col :span="11">
              <a-form-item name="buyCurrency" :label="$t('pages_pay_072')">
                <a-select
                  v-model:value="applyForm.buyCurrency"
                  size="large"
                  allow-clear
                  show-search
                  class="cur-select"
                  :placeholder="$t('common_text_005')"
                  @change="loadDebitCurrencys"
                >
                  <template #suffixIcon>
                    <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                  </template>
                  <a-select-option
                    v-for="item in payeeCurrencys"
                    :key="item"
                    :value="item"
                  >
                    <img :src="getCountryLogo(item)" class="select-img-country">
                    <span>{{ item }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <div v-show="fullParams && applyForm.buyCurrency !== applyForm.sellCurrency" style="margin-bottom: 24px">
            <div v-if="loading" style="justify-content: center" class="flex-align">
              <a-spin :tip="$t('pages_exchange_030')" />
            </div>
            <div v-else class="rate-info flex-align">
              <div class="flex-align">
                <div v-if="!resMsg" class="rate flex-align">
                  <img src="~@/assets/icons/ic-great.png">
                  <span style="margin-left: 8px">{{ $t('pages_exchange_028') }}</span>
                  <span style="margin-left: 3px; font-size: 16px">{{ rateInfo.rate }}</span>
                  <span class="update-date">{{ $t('pages_exchange_029') }} {{ rateInfo.queryDate }}</span>
                </div>
                <div v-else class="rate-error">{{ resMsg }}</div>
                <div class="refresh-process">
                  <count-down
                    :valid-time="rateInfo.validTime"
                    :trade-currency="`${rateInfo.sellCurrency}${rateInfo.buyCurrency}`"
                    @refresh="loadRateInfo"
                  />
                </div>
              </div>
            </div>
            <div v-if="rateInfo.rate" class="explain">
              {{ $t('pages_pay_046') }}
            </div>
          </div>

          <a-form-item v-if="typeOpts.length !== 0" name="paymentMode" :label="$t('pages_pay_078')">
            <div class="settle-list">
              <div
                v-if="typeOpts.includes('SWIFT')"
                class="settle-card"
                :class="applyForm.paymentMode === 'swift' ? 'active' : ''"
                @click="payCardChange('swift')"
              >
                <div class="title">SWIFT</div>
                <div class="content">
                  <div>{{ $t('pages_pay_309') }}</div>
                  <div>{{ $t('pages_pay_310') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_311') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
              <div
                v-if="typeOpts.includes('LOCAL')"
                class="settle-card"
                :style="{marginRight: typeOpts.includes('WALLET') ? '' : 0}"
                :class="applyForm.paymentMode === 'local' ? 'active' : ''"
                @click="payCardChange('local')"
              >
                <div class="title">LOCAL-bank transfer</div>
                <div class="content">
                  <div>{{ $t('pages_pay_313') }}</div>
                  <div>{{ $t('pages_pay_314') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_315') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
              <div
                v-if="typeOpts.includes('WALLET')"
                class="settle-card"
                style="margin-right: 0"
                :class="applyForm.paymentMode === 'wallet' ? 'active' : ''"
                @click="payCardChange('wallet')"
              >
                <div class="title">LOCAL-mobile wallet</div>
                <div class="content">
                  <div>{{ $t('pages_pay_373') }}</div>
                  <div>{{ $t('pages_pay_374') }}、{{ $t('pages_pay_375') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_315') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
            </div>
          </a-form-item>

          <a-form-item v-if="applyForm.paymentMode === 'swift' && openPobo" name="poboPayerName">
            <template #label>
              <span>{{ $t('pages_pay_283') }}</span>
              <a-tooltip :title="$t('pages_pay_285')"><question-circle-filled class="ic-tip" /></a-tooltip>
            </template>
            <div class="settle-list">
              <div
                class="settle-card"
                style="width: 180px"
                :class="applyForm.poboPayerName === merchantName ? 'active' : ''"
                @click="cardChange(merchantName)"
              >
                <div class="title">{{ $t('pages_pay_279') }}</div>
                <div class="content" style="text-align: center">{{ merchantName }}</div>
              </div>
              <div
                class="settle-card"
                style="width: 180px"
                :class="applyForm.poboPayerName === $t('pages_pay_284') ? 'active' : ''"
                @click="cardChange($t('pages_pay_284'))"
              >
                <div class="title">{{ $t('pages_pay_286') }}</div>
                <div class="content" style="text-align: center">{{ $t('pages_pay_284') }}</div>
              </div>
            </div>
          </a-form-item>

          <a-form-item name="path" :label="$t('pages_pay_073')">
            <upload-auto
              ref="uploadRef"
              v-model:file-content="applyForm.path"
              accept=".xls,.xlsx"
              :is-down="true"
              :down-text="applyForm.paymentMode === 'local' ? $t('pages_pay_376', ['bank transfer']) :
                applyForm.paymentMode === 'wallet' ? $t('pages_pay_376', ['mobile wallet']) : $t('pages_pay_055')"
              :notes="$t('pages_pay_287')"
              :explain="$t('pages_pay_288')"
              :max-count="1"
              @down="downTemplate"
            />
          </a-form-item>

          <a-form-item v-if="getUsedCheck(pageLabs, 170299)" style="text-align: right">
            <a-button
              :loading="submitLoading"
              html-type="submit"
              type="primary"
              shape="round"
              style="min-width: 138px; margin-top: 24px"
            >{{ $t('common_text_002') }}</a-button>
          </a-form-item>

        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, h, computed } from 'vue'
import { useRouter } from 'vue-router'
import { _pay, _user, _account, _home } from '@/api'
import { notification, Modal, Input, message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { getCountryLogo, getUsedCheck, exportOutboundsBatchTemplate, parseMoney } from '@/util'
import UploadAuto from '@/components/upload-auto'
import HeaderNav from '@/components/header-nav'
import CountDown from '@/components/count-down'
import i18n from '@/locale'
import moment from 'moment'
export default {
  name: 'OutboundsBatchApply',
  components: {
    'header-nav': HeaderNav,
    'count-down': CountDown,
    'upload-auto': UploadAuto
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return true }
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const validatorRadio = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('common_text_005'))
      return Promise.resolve()
    }

    const applyRef = ref(null)
    const state = reactive({
      rateInfo: {
        validTime: 12
      },
      resMsg: '',
      availableAmount: null,
      openPobo: false,
      loading: false,
      submitLoading: false,
      merchantName: '',
      countries: [],
      curList: [],
      payeeCurrencys: [],
      debitCurrencys: [],
      typeOpts: [],
      pageLabs: [],
      applyForm: {
        countryCode: null,
        buyCurrency: null,
        sellCurrency: null,
        paymentMode: null,
        poboPayerName: null,
        path: ''
      },
      applyRules: {
        path: [{ required: true, message: i18n.global.t('pages_pay_070') }],
        paymentMode: [{ required: true, validator: validatorRadio }]
      }
    })

    state.fullParams = computed(() => {
      const { paymentMode, countryCode, buyCurrency, sellCurrency } = state.applyForm
      return paymentMode && countryCode && buyCurrency && sellCurrency
    })

    const downTemplate = (callback = () => {}) => {
      if (state.fullParams) {
        const data = Object.assign({}, state.applyForm, {
          fileType: 'xls',
          areaCode: '',
          paymentType: ''
        })
        exportOutboundsBatchTemplate(data)
      } else {
        message.error(i18n.global.t('common_text_030'))
      }
      callback()
    }

    const cardChange = (poboPayerName) => {
      state.applyForm.poboPayerName = poboPayerName
    }

    const payCardChange = (paymentMode) => {
      state.applyForm.paymentMode = paymentMode
      applyRef.value.validateFields('paymentMode')
      loadRateInfo()
    }

    const applySubmit = () => {
      state.submitLoading = true
      const { path, paymentMode, poboPayerName } = state.applyForm
      const data = { path }
      if (paymentMode === 'swift' && state.openPobo) {
        data.poboPayerName = poboPayerName
      }
      _pay.outpaymentUploadFileByOss(data)
        .then(res => {
          state.submitLoading = false
          const { data, resCode } = res.data
          if (resCode === '000000') {
            notification.success({
              message: i18n.global.t('common_text_018'),
              description: i18n.global.t('pages_pay_289')
            })
            router.push({ path: '/funds/apply_success', query: { key: 'outbound' }})
          } else if (resCode === '020015') {
            Modal.error({
              title: i18n.global.t('pages_pay_061'),
              content: res.data.resMsg,
              okText: i18n.global.t('common_text_019'),
              onOk: () => {
                window.open(data.failFileName)
              }
            })
          } else if (resCode === '020099') {
            let payerCity = ''
            Modal.confirm({
              title: i18n.global.t('pages_pay_290'),
              content: () => {
                return h(Input, {
                  style: { marginTop: '12px' },
                  placeholder: i18n.global.t('pages_pay_291'),
                  onChange: e => { payerCity = e.target.value }
                })
              },
              okText: i18n.global.t('common_text_002'),
              onOk: async () => {
                if (!payerCity) {
                  message.error(i18n.global.t('common_text_030'))
                } else {
                  const res = await _user.updateKycInfo({ payerCity })
                  if (res.data.succ) message.success(res.data.resMsg)
                  else message.error(res.data.resMsg)
                }
              }
            })
          } else if (resCode === '020100') {
            let payerProvince = ''
            Modal.confirm({
              title: i18n.global.t('pages_pay_290'),
              content: () => {
                return h(Input, {
                  style: { marginTop: '12px' },
                  placeholder: i18n.global.t('pages_pay_292'),
                  onChange: e => { payerProvince = e.target.value }
                })
              },
              okText: i18n.global.t('common_text_002'),
              onOk: async () => {
                if (!payerProvince) {
                  message.error(i18n.global.t('common_text_030'))
                } else {
                  const res = await _user.updateKycInfo({ payerProvince })
                  if (res.data.succ) message.success(res.data.resMsg)
                  else message.error(res.data.resMsg)
                }
              }
            })
          } else if (resCode === '020121') {
            let payerPostalCode = ''
            Modal.confirm({
              title: i18n.global.t('pages_pay_290'),
              content: () => {
                return h(Input, {
                  style: { marginTop: '12px' },
                  placeholder: i18n.global.t('pages_pay_293'),
                  onChange: e => { payerPostalCode = e.target.value }
                })
              },
              okText: i18n.global.t('common_text_002'),
              onOk: async () => {
                if (!payerPostalCode) {
                  message.error(i18n.global.t('common_text_030'))
                } else {
                  const res = await _user.updateKycInfo({ payerPostalCode })
                  if (res.data.succ) message.success(res.data.resMsg)
                  else message.error(res.data.resMsg)
                }
              }
            })
          } else {
            notification.error({
              message: i18n.global.t('common_text_015'),
              description: res.data.resMsg
            })
          }
        }).catch(err => console.log(err))
    }

    const loadRateInfo = async () => {
      state.resMsg = ''
      state.rateInfo = {
        validTime: 12
      }
      const { paymentMode, countryCode, buyCurrency, sellCurrency } = state.applyForm
      if (state.fullParams && buyCurrency !== sellCurrency) {
        state.loading = true
        const res = await _pay.getOutboundTradeRate({
          fileType: 'xls',
          paymentMode,
          countryCode,
          buyCurrency,
          sellCurrency
        })
        if (res.data.succ) {
          const { data } = res.data
          state.rateInfo = Object.assign({}, data, {
            queryDate: moment(data.queryTime).format('YYYY-MM-DD HH:mm:ss'),
            validTime: data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1)
          })
        } else {
          state.resMsg = res.data.resMsg
        }
        state.loading = false
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1702 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadCountries = async () => {
      const res = await _pay.getOutboundsCountries()
      if (res.data.succ) state.countries = res.data.data
    }

    const loadPayeeCurrencys = async (countryCode) => {
      resetParams(
        ['payeeCurrencys', 'debitCurrencys', 'typeOpts'],
        ['buyCurrency', 'sellCurrency', 'paymentMode', 'poboPayerName']
      )
      if (countryCode) {
        const res = await _pay.getPayeeCurrencys({ payeeCountry: countryCode })
        if (res.data.succ) state.payeeCurrencys = res.data.data.payeeCurrencys
        if (state.payeeCurrencys.length > 0) {
          state.applyForm.buyCurrency = res.data.data.defaultCurrency || state.payeeCurrencys[0]
          loadDebitCurrencys(state.applyForm.buyCurrency)
        }
      }
    }

    const loadDebitCurrencys = async (buyCurrency) => {
      resetParams(
        ['debitCurrencys', 'typeOpts'],
        ['sellCurrency', 'paymentMode', 'poboPayerName']
      )
      if (buyCurrency) {
        const { countryCode } = state.applyForm
        const res = await _pay.getDebitCurrencys({ payeeCountry: countryCode, payOutCurrency: buyCurrency })
        if (res.data.succ) state.debitCurrencys = res.data.data
      }
    }

    const loadOutPayType = async (sellCurrency) => {
      resetParams(
        ['typeOpts'],
        ['paymentMode']
      )
      state.availableAmount = null
      if (sellCurrency) {
        const curInfo = state.curList.find(item => item.curType === sellCurrency)
        if (curInfo) state.availableAmount = parseMoney(curInfo.totalAmount)
        const { countryCode, buyCurrency } = state.applyForm
        const res = await _pay.getOutPayType({
          payeeCountry: countryCode,
          payOutCurrency: buyCurrency,
          debitCurrency: sellCurrency
        })
        if (res.data.succ) {
          if (!res.data.data) {
            notification.warning({
              message: i18n.global.t('common_text_037'),
              description: i18n.global.t('pages_pay_282')
            })
          } else {
            state.typeOpts = res.data.data
            if (state.typeOpts.length === 1) state.applyForm.paymentMode = state.typeOpts[0] === 'SWIFT' ? 'swift' : state.typeOpts[0] === 'LOCAL' ? 'local' : 'wallet'
          }
        }
      }
      loadRateInfo()
    }

    const loadCurList = async () => {
      const res = await _home.getCurrency()
      if (res.data.succ) state.curList = res.data.data
    }

    const loadMerchantInfo = async () => {
      const res = await _pay.findByMerchantKycEnrol()
      if (res.data.succ) {
        const { data } = res.data
        state.openPobo = data.products ? data.products.indexOf('POBO') !== -1 : false
      }
    }

    const resetParams = (attrs1, attrs2) => {
      for (const attr1 of attrs1) {
        state[attr1] = []
      }
      for (const attr2 of attrs2) {
        state.applyForm[attr2] = null
      }
    }

    const loadPage = () => {
      const userInfo = store.getters.getUserInfo
      if (userInfo) state.merchantName = userInfo.merchantName
      loadCurList()
      loadCountries()
      loadMerchantInfo()
      loadChildrenMenu()
    }

    loadPage()

    return {
      getCountryLogo,
      getUsedCheck,
      applyRef,
      loadPage,
      loadRateInfo,
      downTemplate,
      applySubmit,
      cardChange,
      payCardChange,
      loadPayeeCurrencys,
      loadDebitCurrencys,
      loadOutPayType,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#outboundsBatchApply {
  .common-card-body {
    text-align: left;
    padding: 24px 48px;
    .batch-header {
      display: inline-block;
      cursor: pointer;
      .ic-back {
        color: @main-color;
      }
    }
    .apply-area {
      display: flex;
      justify-content: center;
      padding: 0 48px;
      .batch-apply-form {
        margin-top: 24px;
        width: 888px;
        .cur-select {
          text-align: center;
          .ic-select {
            width: 12px;
            margin-top: -2px;
          }
        }
        .amount-text {
          font-size: 12px;
          color: @main-color;
          font-weight: 500;
          margin: 0 11px 0 auto;
          .ic-tip {
            margin-right: 3px;
          }
        }
        .rate-info {
          .img-transfer {
            width: 20px;
            margin: 0 8px;
          }
          .rate-error {
            margin-left: 12px;
            color: @error-color;
          }
          .rate {
            font-weight: 500;
            img {
              width: 25px;
              height: 25px;
              margin-top: -6px;
            }
            .update-date {
              font-size: 12px;
              color: #666;
              margin-left: 12px;
            }
          }
          .refresh-process {
            width: 20px;
            height: 20px;
            padding: 2px;
            overflow: hidden;
            margin-left: 5px;
          }
        }
        .explain {
          padding-top: 8px;
          margin-top: 8px;
          font-size: 12px;
          border-top: 1px solid #dfdfdf;
        }
        .radio-area {
          height: auto;
          min-width: 180px;
          text-align: center;
        }
        .ic-tip {
          margin-left: 5px;
        }
      }
      .batch-apply-form:deep(.ant-form-item-label > label) {
        width: 100%;
      }
    }
  }
}
</style>
